import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import './index.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SelectTopic from './pages/SelectTopic';
import Worksheet from './pages/Worksheet';
import UserStats from './pages/UserStats';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Analytics } from '@vercel/analytics/react';


function useGoogleAnalytics() {
  useEffect(() => {
    // Check if the script is already loaded
    if (!window.dataLayer) {
      const scriptTag = document.createElement('script');
      scriptTag.async = true;
      scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-RGBL74T3GP';
      document.body.appendChild(scriptTag);

      window.dataLayer = window.dataLayer || [];
      window.gtag = function() {
        window.dataLayer.push(arguments);
      }
      window.gtag('js', new Date());
      window.gtag('config', 'G-RGBL74T3GP');
    }
  }, []);
}
function Main() {
  useGoogleAnalytics(); 

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <Router>
      <Analytics />
        <Routes>
          
          <Route path="/" exact element={<App />} />
          <Route
            path="/select-topic"
            element={<SelectTopic  />}
          />
          <Route path="/Worksheet" element={<Worksheet />} />
          <Route path="/stats" element={<UserStats/>} />
          
        </Routes>
      </Router>
    </Auth0Provider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById('root')
);
