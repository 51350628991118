import React , { useEffect }  from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import SelectTopic from './pages/SelectTopic';
import GoogleButton from 'react-google-button';
import { Button } from 'react-bootstrap';
import image1 from './img/wisdemicq1.png';
import image2 from './img/wisdemicq2.png';
import AnimatedNumber from './AnimatedNumber';
import RotatingTopic from "./pages/RotatingTopic"; 

import { useNavigate } from 'react-router-dom';

import './App.css';
import './pages/WorksheetStyles.css';



function App() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [savedUser, setSavedUser] = useState(null);
  const navigate = useNavigate();
  const [tokens, setTokens] = useState(null);
  

  useEffect(() => {
    document.title = 'Wisdemic';
  }, []);


  

function IconBook(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20" />
    </svg>
  )
}


  function IconBrain(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M9.5 2A2.5 2.5 0 0 1 12 4.5v15a2.5 2.5 0 0 1-4.96.44 2.5 2.5 0 0 1-2.96-3.08 3 3 0 0 1-.34-5.58 2.5 2.5 0 0 1 1.32-4.24 2.5 2.5 0 0 1 1.98-3A2.5 2.5 0 0 1 9.5 2Z" />
        <path d="M14.5 2A2.5 2.5 0 0 0 12 4.5v15a2.5 2.5 0 0 0 4.96.44 2.5 2.5 0 0 0 2.96-3.08 3 3 0 0 0 .34-5.58 2.5 2.5 0 0 0-1.32-4.24 2.5 2.5 0 0 0-1.98-3A2.5 2.5 0 0 0 14.5 2Z" />
      </svg>
    )
  }
  
  
  function IconChartbar(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <line x1="12" x2="12" y1="20" y2="10" />
        <line x1="18" x2="18" y1="20" y2="4" />
        <line x1="6" x2="6" y1="20" y2="16" />
      </svg>
    )
  }
  
  
  function IconUsers(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
        <circle cx="9" cy="7" r="4" />
        <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
        <path d="M16 3.13a4 4 0 0 1 0 7.75" />
      </svg>
    )
  }
  

  if (isAuthenticated) {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Wisdemic</h1>
          <p>A modern learning platform.</p>
          <Button className = "m-2 shiny-button custom-black-button" onClick={() => navigate('/select-topic')}>Start Learning! </Button>
          <Button className = "m-2" variant = "secondary" onClick={() => logout({ returnTo: window.location.origin })}>Sign Out</Button>
        </header>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-black dark:bg-black overflow-hidden relative">
    <div className="absolute inset-0 bg-gradient-to-r from-transparent to-black opacity-60 z-0" />
    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-5 z-0" />
    <div className="absolute top-0 left-0 w-1/2 h-full bg-gradient-to-r from-white to-transparent opacity-10 z-0" />
    <header className="px-6 py-4 flex items-center justify-between bg-charcoal z-10">
      <p className="text-2xl font-bold text-white" href="#">
        WISDEMIC
      </p>
      <nav className="space-x-4">
       
      </nav>
    </header>
    <main className="flex-1 z-10">
  <section className="flex flex-col-reverse sm:flex-row items-center px-6 py-12 text-center sm:text-left bg-black h-screen">
    <div className="flex-1 px-4 py-12">
      <h1 className="text-2xl sm:text-4xl font-bold text-white">Learn <RotatingTopic/> Through Question and Answers</h1>
      <p className="mt-4 text-lg text-white">Take control of your learning journey with Wisdemic. Get instant feedback and comprehensive explanations for every answer. Start your path to mastery today.</p>
      <Button className="mt-6 bg-white text-black py-2 px-4 rounded" onClick={loginWithRedirect}>Get Started</Button>
    </div>
    <div className="flex-1 mb-6 sm:mb-0">
      <img
        alt="Screenshot"
        className="mx-auto"
        style={{
          maxWidth: "100%", // Ensures image is responsive
          height: "auto", // Ensures image maintains aspect ratio
        }}
        src={image1}
      />
    </div>
  </section>
      <section className="px-6 py-12 text-center">
        <h2 className="text-2xl font-bold text-white">Questions Generated So Far</h2>
        <div className="mt-4 text-4xl font-bold text-white">
        <AnimatedNumber target={789} digits={3}/>
        </div>
      </section>

      <section className="px-4 sm:px-6 py-12 text-center">
  <h2 className="text-xl sm:text-2xl font-bold text-white inline-flex flex-wrap justify-center items-center w-full">
    Join&nbsp;
    <AnimatedNumber className="inline-block mx-2 text-4xl sm:text-5xl" target={108} digits={3}/>
    + other Students now!
  </h2>
</section>
      <section className="px-6 py-12 text-center">
        <h2 className="text-2xl font-bold text-white">Why WISDEMIC?</h2>
        <div className="flex justify-center space-x-8 mt-6">
          <div>
            <IconBook className="text-white h-8 w-8 mb-4" />
            <p className="text-lg text-white">Variety of Subjects</p>
          </div>
          <div>
            <IconBrain className="text-white h-8 w-8 mb-4" />
            <p className="text-lg text-white">Enhanced Learning</p>
          </div>
          <div>
            <IconChartbar className="text-white h-8 w-8 mb-4" />
            <p className="text-lg text-white">Track Progress</p>
          </div>
         
        </div>
      </section>
      <section className="px-6 py-12 text-center">
        <h2 className="text-2xl font-bold text-white">Join WISDEMIC Today</h2>
        <p className="mt-4 text-lg text-white">Unlock your full learning potential. Join us today.</p>
        <Button className="mt-6 bg-white text-black py-2 px-4 rounded" onClick={loginWithRedirect}>Join Now</Button>
      </section>
     
    </main>
    <footer className="px-6 py-4 flex items-center justify-between bg-black text-white border-t border-white z-10">
      <div className="text-sm">© 2023 WISDEMIC.com. All rights reserved.</div>
     
    </footer>
  </div>
  )
}

export default App;
