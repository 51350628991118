import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col, Form, Button, ListGroup } from "react-bootstrap";
import "./WorksheetStyles.css";

function SelectTopic() {
  const [topic, setTopic] = useState("");
  const [previousTopics, setPreviousTopics] = useState([]);
  const [savedUser, setSavedUser] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const recommendedTopics = [
    
    "Biology",
    "Artificial Intelligence",
    "Economics",
    "Geography",
    "Analytics",
    "Anatomy",
    "Roman Empire",
    "Bollywood",
  ];

  const sortedPreviousTopics = previousTopics.sort(
    (a, b) => b.rating - a.rating
  );

  useEffect(() => {

    document.title = 'Wisdemic - Select Topic';
    if (isAuthenticated && user) {
      saveUser(user).then((userData) => {
        setSavedUser(userData);
      });
    }
  }, [isAuthenticated, user]);

  const saveUser = async (user) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/save-user`,
        {
          auth0_id: user.sub,
          name: user.name,
          email: user.email,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error saving user:", error);
    }
  };

  useEffect(() => {
    async function fetchPreviousTopics() {
      // Add a check to make sure savedUser is not null
      if (savedUser) {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/get-user/${savedUser._id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setPreviousTopics(response.data.elo_ratings);
        }
      }
    }

    fetchPreviousTopics();
  }, [getAccessTokenSilently, savedUser]);

  async function saveTopic() {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users/save-topic/${savedUser._id}`,
        {
          topic,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedUser = response.data;
        const eloEntry = updatedUser.elo_ratings.find(
          (rating) => rating.topic === topic
        );

        if (!eloEntry) {
          // Create initial elo rating for the new topic
          const initialRating = 1200;
          const ratingResponse = await axios.put(
            `${process.env.REACT_APP_API_URL}/api/users/update-elo-rating/${savedUser._id}`,
            {
              topic,
              rating: initialRating,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (ratingResponse.status === 200) {
            const updatedUserWithRating = ratingResponse.data;
            setSavedUser(updatedUserWithRating);
            navigate("/worksheet", { state: { topic, elo: initialRating } });
          } else {
            console.error(
              "Error creating initial elo rating for the new topic"
            );
          }
        } else {
          setSavedUser(updatedUser);
          navigate("/worksheet", { state: { topic, elo: eloEntry.rating } });
        }
      } else {
        console.error("Error saving topic");
      }
    } catch (error) {
      console.error("Error in saveTopic function:", error);
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    await saveTopic();
  };

  const handleTopicClick = async (topicInfo) => {
    setTopic(topicInfo.topic);
    navigate("/worksheet", {
      state: { topic: topicInfo.topic, elo: topicInfo.rating },
    });
  };

  const handleRecommendedTopicClick = async (topic) => {
    setTopic(topic);
    navigate("/worksheet", { state: { topic: topic } });
  };

  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <h2>What do you want to learn today?</h2>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="topic">
              <Form.Control
                type="text"
                className ="w-50"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                placeholder="Type a topic"
              />
            </Form.Group>
            <button type="submit" class="shiny-button">
              🚀 Start Quiz!
            </button>
          </Form>
        </Col>
      </Row>
      {previousTopics.length > 0 && (
  <div className="mt-5">
    <h3 className="text-lg font-semibold mb-4">Choose from a Previous Topics</h3>
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
      {sortedPreviousTopics.map((topicInfo, index) => (
        <div
          key={index}
          className="border p-4 rounded-lg shadow-md hover:shadow-lg cursor-pointer"
          onClick={() => handleTopicClick(topicInfo)}
        >
          <h4 className="text-md font-bold">{topicInfo.topic}</h4>
          <p className="text-sm text-gray-600">Elo rating: {topicInfo.rating}</p>
        </div>
      ))}
    </div>
  </div>
)}

<div className="mt-5">
  <h3 className="text-lg font-semibold mb-4">Explore few of the Recommended Topics</h3>
  <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
    {recommendedTopics.map((topic, index) => (
      <div
        key={index}
        className="border p-4 rounded-lg shadow-md hover:shadow-lg cursor-pointer"
        onClick={() => handleRecommendedTopicClick(topic)}
       >
        <h4 className="text-md font-bold">{topic}</h4>
      </div>
    ))}
  </div>
</div>
</Container>


)}


export default SelectTopic;
