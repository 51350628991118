import React, { useRef, useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';

function AnimatedNumber({ target, digits = 3 }) {
  const [isInView, setIsInView] = useState(false);
  const ref = useRef();
  
  const props = useSpring({
    number: isInView ? target : 0,
    from: { number: 0 },
    config: {
      duration: 2000, // Duration in milliseconds
    }
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // When the element is in the viewport, set isInView to true
        if (entry.isIntersecting) {
          setIsInView(true);
          // Optional: Unobserve the element after it's in view
          observer.unobserve(ref.current);
        }
      },
      {
        // Set the threshold to trigger the callback when the target is fully visible
        threshold: 1.0
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <animated.div ref={ref}>
      {props.number.interpolate(n => 
        String(Math.floor(n)).padStart(digits, '0')
      )}
    </animated.div>
  );
}

export default AnimatedNumber;
