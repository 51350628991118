import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Alert,
  Form,
  Spinner,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./WorksheetStyles.css";

const openaiAPIKey = process.env.REACT_APP_openaiAPIkey;

function Worksheet() {
  const [questionData, setQuestionData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [formKey, setFormKey] = useState(0);
  const [remainingTokens, setRemainingTokens] = useState(50);
  const [nextQuestionData, setNextQuestionData] = useState(null);
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [nextQuestionLoaded, setNextQuestionLoaded] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const topic = location.state?.topic;
  const [elo, setElo] = useState(location.state?.elo || 800);

  const fetchRemainingTokens = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/get-tokens`,
        { auth0_id: user.sub },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRemainingTokens(response.data.tokens);
    } catch (error) {
      console.error("Error fetching remaining tokens:", error);
    }
  }, [getAccessTokenSilently, user.sub]);


  async function decrementTokens() {
    const token = await getAccessTokenSilently();
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/users/decrement-tokens`,
      { auth0_id: user.sub },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      setRemainingTokens(remainingTokens - 1);
    } else {
      console.error("Error decrementing tokens");
    }
  }

  const getQuestion = async (setter, callback) => {
    if (!topic) {
      navigate("/select-topic");
      return;
    }
  
    try {
      const response = await axios.post('/api/openai', { topic, elo });
  
      // Handle the response as needed
     
      const QuestionText = response.data.choices[0].message.content;

      const QuestionObj = JSON.parse(QuestionText);

      const questionData = {
        question: QuestionObj.Question,
        options: [
          QuestionObj["Option A"],
          QuestionObj["Option B"],
          QuestionObj["Option C"],
          QuestionObj["Option D"],
        ],
        correctAnswer: QuestionObj.RightOption,
        explanation: QuestionObj["Explanation"],
      };

      setter(questionData);
      setIsLoading(false);

      if (typeof callback === "function") {
        callback(questionData);
      }
      setNextQuestionLoaded(true);
    } catch (error) {
      console.error("Error getting Question:", error);
    }
  };

  async function saveQuestion(questionData) {
    const token = await getAccessTokenSilently();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/questions/create`,
        questionData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        console.error("Error saving question");
      }
    } catch (error) {
      console.error("Error saving question:", error);
    }
  }

  // Add a function to update the user's Elo rating
  async function updateUserElo(elo) {
    const token = await getAccessTokenSilently();

    // Extract the user ID from user.sub
    const userId = user.sub;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users/update-elo`,
        { auth0_id: userId, topic, elo },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        console.error("Error updating user elo");
      }
    } catch (error) {
      console.error("Error updating user elo:", error);
    }
  }

  // Add a function to save the answer to the user's profile
  // async function saveAnswer(answerData) {
  //   // ...

  //   const token = await getAccessTokenSilently();
  //   const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/save-answer/${user.sub}`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${token}`,
  //     },
  //     body: JSON.stringify(answerData),
  //   });

  //   if (!response.ok) {
  //     console.error('Error saving answer');
  //   }
  // }
  const handleReportClick = () => {
    // Update questionData.flagged to true
    setQuestionData({ ...questionData, flagged: true });

    // Show the modal
    setShowReportModal(true);

    // Set a timer to auto-close the modal after 7 seconds
    setTimeout(() => {
      setShowReportModal(false);
    }, 14000);
  };

  const handleNextClick = async () => {
    setNextQuestionLoaded(false);
    setQuestionData(nextQuestionData);

    const mcqData = {
      topic,
      question: questionData.question,
      optionA: questionData.options[0],
      optionB: questionData.options[1],
      optionC: questionData.options[2],
      optionD: questionData.options[3],
      correctAnswer: questionData.correctAnswer,
      explanation: questionData.explanation,
      userAnswer: selectedOption,
      isCorrect: selectedOption === questionData.correctAnswer,
      elo: elo,
      flagged: questionData.flagged,
    };

    // Save the MCQ data using the saveQuestion function
    await saveQuestion(mcqData);
    // Save the answer
    // await saveAnswer({
    //   topic,
    //   question: questionData.question,
    //   answer: selectedOption,
    //   correct: selectedOption === questionData.correctAnswer,
    // });

    // Update the user's Elo rating

    // Reset the state for the new question
    setAnswerSubmitted(false);
    setSelectedOption(null);
    setShowAnswer(false);
    setIsAnswerCorrect(null);
    setFormKey((prevFormKey) => prevFormKey + 1); // Add this line
    setQuestionData(nextQuestionData);

    // Fetch the next question
    if (remainingTokens > 0) {
      decrementTokens();
      getQuestion(setNextQuestionData);
    } else {
      alert(
        "No more tokens remaining for today. Tokens will refresh tomorrow."
      );
    }
  };

  useEffect(() => {
    document.title = 'Wisdemic - Quiz';
    if (isAuthenticated && user) {
      fetchRemainingTokens();
      const fetchQuestions = async () => {
        await getQuestion(setQuestionData, async () => {
          if (remainingTokens > 0) {
            await decrementTokens();
            await getQuestion(setNextQuestionData);
          } else {
            alert("No more tokens remaining for today. Tokens will refresh tomorrow.");
          }
        });
      };
      fetchQuestions();
    }
  }, [isAuthenticated, user, fetchRemainingTokens]);

  const handleShareClick = async () => {
   
      const shareText = `${questionData.question}\n\nOptions:\nA. ${questionData.options[0]}\nB. ${questionData.options[1]}\nC. ${questionData.options[2]}\nD. ${questionData.options[3]}`;
    
      if (navigator.share) {
        navigator
          .share({
            title: "Check out this question!",
            text: shareText,
            url: window.location.href,
          })
          .then(() => console.log("Successful share"))
          .catch((error) => console.log("Error sharing", error));
      } else {
        console.warn("Web Share API not supported, copying text to clipboard instead.");
    
        try {
          await navigator.clipboard.writeText(shareText);
          console.log("Text copied to clipboard");
          // Show a message to the user that the text has been copied.
          alert("Question text copied to clipboard. You can now paste it into any app.");
        } catch (err) {
          console.error("Failed to copy text: ", err);
        }
      }
    };
    
    
  


  const handleSubmitClick = (event) => {
    event.preventDefault();
    setAnswerSubmitted(true);
 

    const correct = selectedOption === questionData.correctAnswer;

    setIsAnswerCorrect(correct);

    if (correct) {
      setElo(Math.round(elo + 200 * (1 - Math.exp(-400 / elo))));
    } else {
      setElo(Math.round(elo - 200 * (1 - Math.exp(elo * -0.0005))));
    }

    updateUserElo(elo);

    setShowAnswer(true);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  // ...


  return (
    <Container>
      <div className="d-flex justify-content-end">
        <div className="m-2">
          <h5>Tokens: {remainingTokens}</h5>
        </div>
        <div className="m-2">
          <h5>Skill Level: {elo}</h5>
        </div>
        <div className="m-2">
          <Button as={Link} to="/select-topic">
            Topics
          </Button>
        </div>
      </div>

      {isLoading ? (
        <Row className="mt-5">
          <Col className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only"> </span>
            </Spinner>
            <p>Generating a question unique to you...</p>
          </Col>
        </Row>
      ) : questionData ? (
        <>
          <Row className="mt-5">
            <Col>
              <h1>{questionData.question}</h1>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <h5>Choose the right answer</h5>
            </Col>
          </Row>
          <Form key={formKey} onSubmit={(event) => handleSubmitClick(event)}>
            <Row className="mt-4">
              <Col>
                {questionData.options.map((option, index) => (
                  <Form.Check
                    key={index}
                    type="radio"
                    id={`option-${index}`}
                    label={option}
                    value={`Option ${String.fromCharCode(65 + index)}`}
                    name="options"
                    onChange={handleOptionChange}
                    disabled={showAnswer}
                    className="mb-3"
                  />
                ))}
              </Col>
            </Row>
            {selectedOption && !showAnswer && (
              <Row className="mt-4">
                <Col>
                  <Button variant="success" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
          {answerSubmitted && showAnswer && (
            <>
              <Row className="mt-4 w-md-50">
                <Col>
                  <Alert variant={isAnswerCorrect ? "success" : "danger"}>
                    {isAnswerCorrect ? "Correct!" : "Incorrect!"}
                    {` The right answer is ${questionData.correctAnswer}: ${
                      questionData.options[
                        questionData.correctAnswer.split(" ")[1].charCodeAt(0) -
                          65
                      ]
                    }`}
                  </Alert>
                </Col>
              </Row>
              <Row className="mt-4 w-md-50">
                <Col>
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        <strong>Explanation:</strong>
                      </Card.Title>

                      <Card.Text>{questionData.explanation}</Card.Text>
                      <Button
                        variant="outline-secondary"
                        onClick={handleReportClick}
                      >
                        Report Question
                      </Button>
                      <Button
                        variant="secondary"
                        className="ml-3 m-2"
                        onClick={handleShareClick}
                      >
                        Share Question
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  {nextQuestionLoaded ? (
                    <Button variant="primary" onClick={handleNextClick}>
                      Next
                    </Button>
                  ) : (
                    <div className="bouncing-loader m-2">
                      <div className="bouncing-ball"></div>
                      <div className="bouncing-ball"></div>
                      <div className="bouncing-ball"></div>
                    </div>
                  )}
                </Col>
              </Row>
              <div className="footer-space"></div>
              <div className="madeby-footer">
                Made with ❤️ by{" "}
                <a
                  href="https://twitter.com/asifkabeer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Asif
                </a>
              </div>
            </>
          )}
        </>
      ) : (
        <Row className="mt-5">
          <Col>
            <p>
              Hang tight! We're cooking up a personalized quiz just for you...
            </p>
            <div className="loading-bar-container">
              <div className="loading-bar"></div>
            </div>
          </Col>
        </Row>
      )}
      {showReportModal && (
        <Modal show={showReportModal} onHide={() => setShowReportModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Thanks for reporting the question</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Our questions are generated by an AI model that can showcase
            incorrect answers at times. Your support will help us improve.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowReportModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );

      }
export default Worksheet;
