import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';

const UserStats = ({ auth0Id }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (auth0Id) {
      getUserStats(user._id);
    }
  }, [auth0Id]);

  const getUserStats = async (userId) => {
    try {
      const response = await axios.get(`/api/users/get-stats/${userId}`);
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user stats:', error);
    }
  };

  if (!user) {
    return <div>Loading user stats...</div>;
  }

  const { name, email, elo_ratings } = user;

  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <h1 className="text-center">User Stats</h1>
          <h2 className="text-center">{name}</h2>
          <p className="text-center">{email}</p>
        </Col>
      </Row>
      <Row className="mt-4">
        {elo_ratings.map((rating, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{rating.topic}</Card.Title>
                <Card.Text>
                  <strong>Rating:</strong> {rating.rating}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default UserStats;
