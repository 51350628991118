import React, { useState, useEffect } from "react";
import '../App.css'; // Ensure this points to the correct path

const sampleTopics = ["Science", "Algebra", "Anatomy", "React", "Astronomy"];

const RotatingTopic = () => {
  const [currentTopicIndex, setCurrentTopicIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsAnimating(true); // Start fade-out animation
      setTimeout(() => {
        setCurrentTopicIndex(prevIndex => (prevIndex + 1) % sampleTopics.length);
        setIsAnimating(false); // Start fade-in animation
      }, 500); // Half duration of animation
    }, 2000); // Change topic every 3 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="topic-container stroke-white-500 underline">
      <span className={`word-animate fade-slide ${isAnimating ? 'out' : 'in'}`}>
        {sampleTopics[currentTopicIndex]}
      </span>
    </div>
  );
};

export default RotatingTopic;
